import Vue from 'vue'
import Router from 'vue-router'

import layout from '../layout'

Vue.use(Router)

export default new Router({
  linkExactActiveClass: 'active',
  scrollBehavior (to) {
    if (to.hash) {
      return { selector : to.hash}
    }else {
      return {y:0}
    }
  }, 
  mode: 'history',
  base: '/',
  routes: [
    {
      path: '',
      component:layout,
      children: [
        {
          path:'',
          name: 'home',          
          component: () => import ('@/pages/pr/home-3')
        }
      ]
    },
    {
      path: '/home2',
      component:layout,
      children: [
        {
          path:'',
          name: 'home2',          
          component: () => import ('@/pages/pr/home-2')
        }
      ]
    },
    {
      path: '/home3',
      component:layout,
      children: [
        {
          path:'',
          name: 'home3',          
          component: () => import ('@/pages/pr/home-3')
        }
      ]
    },
    {
      path: '/qui-suis-je',
      component:layout,
      // beforeEnter : guardMyroute,
      children: [
        {
          path:'',
          name: 'qui-suis-je',
          component: () => import ('@/pages/pr/qui-suis-je')
        }
      ]
    },
    {
      path: '/contact',
      component:layout,
      // beforeEnter : guardMyroute,
      children: [
        {
          path:'',
          name: 'contact',
          component: () => import ('@/pages/pr/contact')
        }
      ]
    },
    {
      path: '/services',
      component:layout,
      // beforeEnter : guardMyroute,
      children: [
        {
          path:'',
          name: 'services',
          component: () => import ('@/pages/pr/services')
        }
      ]
    },
    {
      path: '/test',
      component:layout,
      // beforeEnter : guardMyroute,
      children: [
        {
          path:'',
          name: 'test',
          component: () => import ('@/pages/pr/test')
        }
      ]
    },
  ]
})
