import Vue from 'vue'
import App from './App.vue'
import router from './router/cpts'
import BootstrapVue from 'bootstrap-vue'
import VueSweetalert2 from 'vue-sweetalert2'
import ProductZoomer from 'vue-product-zoomer'
import store from './store'
import checkView from 'vue-check-view'
import Vuelidate from 'vuelidate'

import AOS from 'aos'
import 'aos/dist/aos.css';
import device from "vue-device-detector"
import { Icon } from 'leaflet';


//leaflet map 
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});
Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);

Vue.use(ProductZoomer)
Vue.use(BootstrapVue)
Vue.use(VueSweetalert2)
Vue.use(checkView)
Vue.use(Vuelidate)
Vue.use(device)

AOS.init()
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
