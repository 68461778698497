<template>
  <section class="app-footer">
    <footer class="footer">
      <div class="container-fluid clearfix">

        <!-- rangée 1 -->
        <div class="">
          <div class="d-flex align-items-center justify-content-around">
            <img src="@/assets/images/import/cpts/logo-lyon-2.png" alt="">
          </div>
        </div>
        <span class="border-right computer"></span>

        <!-- rangée 2 -->
        <div class="contact d-flex flex-column align-items-around justify-content-around px-md-4 px-sm-0">
          <div class="d-flex justify-content-around align-items-center">
            <i class="fa fa-map-marker computer"></i>
            <p>Adresse <br> Code postale - Ville</p>    
          </div>
          <div class="d-flex justify-content-around align-items-center">
            <i class="fa fa-phone computer"></i> 
            <p>+33 617 219 111 </p>
          </div>
          <div class="d-flex justify-content-around align-items-center">
            <p> Association loi 1901</p>
          </div>
        </div>
        <span class="border-right computer"></span>

        <!-- rangée 3 -->
        <div class="row-3">
          <ul>
            <li>Notre CPTS</li>
            <li>Recherche médecin traitant</li>
            <li>N° d'urgence</li>
            <li>Actualités</li>
            <li>Contact</li>
          </ul>
          <div class="d-flex justify-content-between">
            <label>Conditions générales</label>
            <label>Mentions légales</label>
          </div>
        </div>
        <!-- rangée 4 -->
        <span class="border-right"></span>
        <div class="d-flex flex-column justify-content-around align-items-center" style="max-width:15rem">
          <h3 class="computer"> Ensemble, dynamisons la santé sur notre Territoire</h3>
          <label for="" class="text-muted text-center mobile">Ensemble, dynamisons la santé sur notre Territoire</label>
          <!-- <img class="mini-logo" src="@/assets/images/import/cpts/logo-mini.png" alt=""> -->
        </div>
      </div>
    </footer>
  </section>
</template>

<script>
export default {
  name: 'app-footer'
}
</script>
<style lang="scss" scoped>
.footer{
  >div{
    display:flex;
    justify-content: space-between;
  }
}
@media screen and (min-width:992px){
  .row-3{
    min-width: 18rem;
  }
}
//   si mobile
@media screen and (max-width: 992px){
  .footer{
  >div{
    display:flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
}

footer {
  background: #2A6238;
}
footer p, footer ul li, footer label, footer h3 {
  color: #fff;
}
.social-img {
  width: 4rem;
}
::v-deep .contact .fa, .contact .mdi{
  font-size:2rem
}
.mini-logo {
  width: 5rem;
}
.border-right {
  border-right: 2px rgb(255, 254, 254) solid !important;
}
.app-footer {
  z-index: 20;
}
</style>