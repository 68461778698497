<template>
<div>
    <b-navbar id="template-header" class="navbar default-layout-navbar p-0 computer" toggleable="sm">
    <div class="horizontal-menu">
      <nav class="navbar top-navbar col-lg-12 col-12 ">
        <div class="container ">
          <div class=" navbar-brand-wrapper">
            <router-link class="navbar-brand brand-logo " to="/">
              <img class="principal-logo" src="@/assets/images/logo.png" alt="logo" />
            </router-link>            
          </div>
          <div class="navbar-brand-wrapper ">
            <p class="tx-1 Tangier_Black">Parfait Recrutement</p>
            <p class="tx-2">Consultant indépendant RH et Recrutement</p>
          </div>
          <div class="navbar-menu-wrapper d-flex align-items-center justify-content-center navlist">
            <div class="d-flex justify-content-center menu-list" >
              <router-link class="mx-3" to="/">Accueil</router-link>
              <router-link class="mx-3" to="/qui-suis-je">Qui suis-je?</router-link>
              <router-link class="mx-3" to="/contact">Contact</router-link>
            </div>
          </div>
        </div>
      </nav>
    </div>
  </b-navbar>

   <b-navbar class="mobile" toggleable>
      <b-navbar-brand href="/">
              <img class="principal-logo" src="@/assets/images/logo.png" alt="logo" />

    </b-navbar-brand>
    <b-navbar-brand  href="/">
    <p class="tx-1-mobile Tangier_Black">
      Parfait Recrutement</p>
    <p class="tx-2-mobile">
      Consultant indépendant RH <br> et Recrutement</p>
    </b-navbar-brand>
    <b-navbar-toggle target="navbar-toggle-collapse">
      <template #default="{ expanded }">
        <i  v-if="expanded" class="fa fa-minus"></i>
        <i v-else  class="fa fa-bars"></i>
      </template>
    </b-navbar-toggle>

    <b-collapse id="navbar-toggle-collapse" is-nav>
      <b-navbar-nav class="ml-auto text-right menu-list">
        <b-nav-item  to="/">Accueil</b-nav-item>
        <b-nav-item to="/qui-suis-je">Qui suis-je?</b-nav-item>
        <b-nav-item to="/contact">Contact</b-nav-item>

      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</div>
  
</template>

<script>
export default {
  name: 'app-header',
  
}
</script>

<style scoped>
.principal-logo {
  max-width: 100%;
  height: 65px !important;
  margin: auto;
  vertical-align: middle;
}
.tx-1{
  /* font-family:'Script MT Bold'; */
  font-size: 1.3rem;
  margin-bottom: 0px;
  margin-top: 8px;
}
.tx-2{
  /* font-family: 'Noto Sans'; */
  line-height: 15px;
  font-size: 0.77rem;
  width: 170px;
}

.tx-1-mobile{
  /* font-family:'Script MT Bold'; */
  font-size: 1rem;
  margin-bottom: 0px;
  margin-top: 8px;
}

.tx-2-mobile{
  /* font-family: 'Noto Sans'; */
  line-height: 15px;
  font-size: 0.77rem;
  max-width: 60px;
}
.menu-list {
  font-family: 'Noto Sans';
  text-transform: uppercase;
  color: black !important;
  font-weight: 500;
}

a{
  color:black;
}
.horizontal-menu .top-navbar{
  border: none !important;
}
.navbar{
  background-color:  rgba(248, 248, 248, 0);
  z-index:999 ;
}
</style>