/*
*On stock les données centralisée avec Vuex
*afin de fournir des fonctionnalités avancées
*/
import Vue from'vue'
import Vuex from 'vuex'
import axios from 'axios'
import router from './router/pr'

Vue.use(Vuex)

export default new Vuex.Store({
    state:{
        idToken:null,
        pseudo: null
    },
    mutations:{
        authUser(state,userData){
            state.idToken= userData.token,
            state.pseudo = userData.pseudo

        }
    },
    actions:{
        login({commit},authData){
            console.log(authData)
            //se connecter avec axios
            axios.post('https://sites.comolapapaya.com/login',authData)
            .then(
                res => {console.log(res)
                //Enregistrer l'utilisateur dans authUser
                commit('authUser',{
                    token:res.data.token,
                    pseudo:res.data.pseudo
                })
                //Utiliser local storage
                localStorage.setItem('token',res.data.token)
                localStorage.setItem('pseudo',res.data.pseudo)
                //date d'expiration de local storage
                localStorage.setItem('expiresIn',res.data.expires)
                //redirection aprés l'enregistrement
                router.replace('/')
                }
            )
            .catch(
                err => console.log(err)
            )
        },
        //Charger dans localstorage
        AutoLogin({commit}){ 
            const token = localStorage.getItem('token')
            if (!token) {
                return
            }
            const date_dexpiration = localStorage.getItem('expiresIn')
            const now = new Date()
            if(now <= date_dexpiration){
                return
            }
            const pseudo =localStorage.getItem('pseudo')
            commit('authUser',{
                token:token,
                pseudo:pseudo
            })
        }
    },
    getters:{
        //expracter pseudo de state
        pseudo(state){
            return state.pseudo
        }
    }
})